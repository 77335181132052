<template>
  <Library v-if="true" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import Library from '../components/library/Library.vue';

export default {
  components: {
    Library,
    'p-not-found': NotFound
  }
};
</script>
