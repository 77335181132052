<template>
  <div :title="category.description" class="category-header" @click="$emit('selected')">
    <div :class="[...handleClasses]">
      <MdIcon name="folder" />
    </div>
    <span @click="onTitleClick">
      {{ category.name }}<span v-if="count != null">({{ count }})</span>
    </span>

    <Menu v-if="showActions" :options="actions" direction="right" @click.stop="" @selected="onAction">
      <MdIcon name="cog-outline" />
    </Menu>
    <span v-else></span>
    <Button @click.stop="$emit('expanded')">
      <MdIcon :name="expanded ? 'chevron-right' : 'chevron-down'" />
    </Button>
  </div>
</template>

<script>
import MdIcon from '@/components/common/MdIcon.vue';
import Button from '@/components/common/Button.vue';
import Menu from '../common/Menu.vue';

export default {
  components: {
    MdIcon,
    Button,
    Menu
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    handleClasses: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const actions = [
      { key: 'edit', label: 'Edit', icon: 'pencil' },
      { key: 'delete', label: 'Delete', icon: 'delete-alert' }
    ];
    if (!this.category.parentId) {
      actions.unshift({ key: 'add-subcategory', label: 'Add Subcategory', icon: 'plus' });
    }
    return {
      isEditing: false,
      actions
    };
  },
  methods: {
    onTitleClick() {
      if (this.category.id === 'other') {
        return;
      }
      this.isEditing = !this.isEditing;
    },
    onAction(option) {
      this.$emit('action', option);
    }
  }
};
</script>

<style scoped>
.category-header,
.subcategory-header {
  display: grid;
  grid-template-columns: 30px 1fr 30px 30px;
  align-items: center;
}
</style>
