<template>
  <section class="create-prompt">
    <header>
      <h1>New Prompt</h1>
    </header>
    <p-edit-prompt-form
      :is-request-pending="isCreateRequestPending"
      :is-request-failed="isCreateRequestFailed"
      @submit="submit"
      @cancel="$router.back()"
    />
    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import CreateForm from './_CreateOrEdit';

export default {
  components: {
    'p-edit-prompt-form': CreateForm
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.prompts.isCreateRequestPending,
      isCreateRequestFailed: s => s.prompts.isCreateRequestFailed
    })
  },
  methods: {
    async submit({ id, ...props }) {
      const resp = await this.$store.dispatch('prompts/create', props);
      this.$router.push({ path: `/prompts/${resp.id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
.create-prompt {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 0 1rem;

  header {
    width: 100%;
    padding: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
    position: relative;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }

  .action-wrapper {
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
