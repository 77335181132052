<template>
  <div class="input-wrapper">
    <input class="search-input" @input="onInput" />
    <div class="icon-wrapper">
      <div class="icon-holder">
        <MdIcon size="sm" name="magnify" />
      </div>
    </div>
  </div>
</template>

<script>
import MdIcon from '@/components/common/MdIcon';
export default {
  components: {
    MdIcon
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  .search-input {
    width: 100%;
    height: 33px;
    outline: none;
    padding-left: 10px;
    margin: 0;
    box-sizing: border-box;
    padding-right: 25px;
    border: 1px solid #5d4ce8;
    background-color: #eceaff;
    border-radius: 20px;
  }
  .icon-holder {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #5d4ce8;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
