import { cruduex, am, factory } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

const EXEC = am(`EXEC_SPECIFICATION`);
const PARAMS = am(`PARAMS_SPECIFICATION`);
const INITIALIZE = am(`SAMPLE_INITIALIZE`);
const GET_CONTEXT_OBJECT = am(`GET_CONTEXT_OOBJECT`);

const GET_HELP = am(`GET_HELP`);

export default {
  namespaced: true,
  ...factory({
    state: {
      isGetHelpPending: false,
      isGetHelpFailed: false,
      help: null
    },
    mutations: {
      [GET_HELP.STARTED](state) {
        state.isGetHelpPending = true;
        state.isGetHelpFailed = false;
      },
      [GET_HELP.COMPLETED](state, help) {
        state.isGetHelpPending = false;
        state.help = help;
      },
      [GET_HELP.FAILED](state) {
        state.isGetHelpFailed = true;
        state.isGetHelpPending = false;
      }
    },
    actions: {
      async getHelp({ commit }) {
        try {
          commit(GET_HELP.STARTED);

          const response = await httpClient.get(`/api/nlp/api/v1/generator/documentation`);

          commit(GET_HELP.COMPLETED, response);
        } catch (e) {
          commit(GET_HELP.FAILED);
          throw e;
        }
      }
    }
  })(
    'specification',
    {
      async getById(id) {
        return httpClient.get(`/api/specifications/${id}`);
      },
      async getCollection() {
        return httpClient.get('/api/specifications/');
      },
      async create(payload) {
        return httpClient.post('/api/specifications/', payload);
      },
      async update(id, args) {
        return httpClient.patch(`/api/specifications/${id}`, args);
      },
      async delete(id) {
        return httpClient.delete(`/api/specifications/${id}`);
      }
    },
    {
      sample: {
        namespaced: true,
        state: {
          isRequestPending: false,
          isRequestFailed: false,
          response: '',

          isGetContextObjectRequestPending: false,
          isGetContextObjectRequestFailed: false,
          contextObject: null,
          document: null
        },
        mutations: {
          [INITIALIZE.STARTED](state) {
            state.isRequestPending = false;
            state.isRequestFailed = false;
            state.response = '';
            state.contextObject = null;
            state.document = null;
          },
          [EXEC.STARTED](state) {
            state.isRequestPending = true;
            state.isRequestFailed = false;
          },
          [EXEC.COMPLETED](state, response) {
            state.isRequestPending = false;
            state.response = response;
          },
          [EXEC.FAILED](state, response) {
            state.isRequestPending = false;
            state.isRequestFailed = true;
            state.response = response;
          },
          [PARAMS.STARTED](state) {
            state.isParamsRequestPending = true;
            state.isParamsRequestFailed = false;
          },
          [PARAMS.COMPLETED](state, { params }) {
            state.isParamsRequestPending = false;
            state.params = params;
          },
          [PARAMS.FAILED](state) {
            state.isParamsRequestPending = false;
            state.isParamsRequestFailed = true;
          },

          [GET_CONTEXT_OBJECT.STARTED](state) {
            state.isGetContextObjectRequestPending = true;
            state.isGetContextObjectRequestFailed = false;
            state.response = '';
            state.contextObject = null;
            state.document = null;
          },
          [GET_CONTEXT_OBJECT.COMPLETED](state, { contextObject, document }) {
            state.isGetContextObjectRequestPending = false;
            state.contextObject = contextObject;
            state.document = document;
          },
          [GET_CONTEXT_OBJECT.FAILED](state) {
            state.isGetContextObjectRequestPending = false;
            state.isGetContextObjectRequestFailed = true;
          }
        },
        actions: {
          async initialize({ commit }) {
            commit(INITIALIZE.STARTED);
          },
          async exec({ commit }, { name, template, document }) {
            try {
              commit(EXEC.STARTED);

              const { claims } = await httpClient.post(`/api/nlp/api/v1/parser/parse`, {
                document,
                sections: ['claims']
              });

              const data = await httpClient.post(`/api/nlp/api/v1/generator/generate`, {
                templates: [
                  {
                    name,
                    body: template,
                    params: [],
                    claims: claims.claims.map(({ claimNumber }) => ({
                      number: claimNumber,
                      params: []
                    }))
                  }
                ],
                document
              });

              const response = data.paragraphs.map(p => p.runs.map(r => r.text).join('')).join('\r\n\n\n');

              commit(EXEC.COMPLETED, response && response.length ? response : '<An empty string was generated..>');
            } catch (e) {
              logger.error(e);

              try {
                const responseText = await e.response.text();
                const { message } = JSON.parse(responseText);
                commit(EXEC.FAILED, message);
              } catch (e) {
                commit(EXEC.FAILED, 'Unable to generate an output.');
              }
            }
          },
          async params({ commit }, { template }) {
            try {
              commit(PARAMS.STARTED);
              const { params } = await httpClient.post(`/api/specifications/params`, {
                template
              });

              commit(PARAMS.COMPLETED, { params });

              return { params };
            } catch (e) {
              commit(PARAMS.FAILED);
              throw e;
            }
          },
          async getContextObject({ commit }, { document, template, name }) {
            try {
              commit(GET_CONTEXT_OBJECT.STARTED);
              const { claims } = await httpClient.post(`/api/nlp/api/v1/parser/parse`, {
                document,
                sections: ['claims']
              });

              const data = await httpClient.post(`/api/nlp/api/v1/generator/contextObject`, {
                templates: [
                  {
                    name,
                    body: template,
                    params: [],
                    claims: claims.claims.map(({ claimNumber }) => ({
                      number: claimNumber,
                      params: []
                    }))
                  }
                ],
                document
              });

              const contextObject = data[name];
              commit(GET_CONTEXT_OBJECT.COMPLETED, { document, contextObject });
            } catch (e) {
              commit(GET_CONTEXT_OBJECT.FAILED);
              throw e;
            }
          }
        }
      }
    }
  )
};
