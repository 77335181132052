import Vue from 'vue';
import Router from 'vue-router';

import Home from './views/Home';
import People from './views/People';
import CreateUser from './views/CreateUser';
import EditUser from './views/EditUser';
import Teams from './views/Teams';
import CreateTeam from './views/CreateTeam';
import EditTeam from './views/EditTeam';
import ChangePassword from './views/ChangePassword';
import Permissions from './views/Permissions';
import UserSettings from './views/UserSettings.vue';
import Specifications from './views/Specifications';
import Specification from './views/Specification';
import CreateSpecification from './views/CreateSpecification';
import EditSpecification from './views/EditSpecification';
import Portfolio from './views/Portfolio';
import CreateApplication from './views/CreateApplication';
import Forms from './views/Forms';
import CreateForm from './views/CreateForm';
import EditForm from './views/EditForm';
import Processing from './views/Processing';
import Uspto from './views/Uspto';
import Fip from './views/Fip';
import Applications from './views/Applications';
import Categorization from './views/Categorization';
import WordDocuments from './views/WordDocuments';
import CreateWordDocument from './views/CreateWordDocument';
import EditWordDocument from './views/EditWordDocument';
import VisioDocuments from './views/VisioDocuments';
import CreateVisioDocument from './views/CreateVisioDocument';
import EditVisioDocument from './views/EditVisioDocument';
import Drawings from './views/Drawings';
import CreateDrawing from './views/CreateDrawing';
import EditDrawing from './views/EditDrawing';
import Messages from './views/Messages';
import CreateMessage from './views/CreateMessage';
import EditMessage from './views/EditMessage';
import Workflows from './views/Workflows';
import CreateWorkflow from './views/CreateWorkflow';
import EditWorkflow from './views/EditWorkflow';
import Workflow from './views/Workflow';
import WorkflowEditor from './views/WorkflowEditor';
import EmailsConnector from './views/EmailsConnector';
import EditModelConfiguration from './views/EditModelConfiguration';
import CreateModelConfiguration from './views/CreateModelConfiguration';
import Library from './views/Library.vue';

import Prompts from './views/Prompts';
import Prompt from './views/Prompt';
import CreatePrompt from './views/CreatePrompt';
import EditPrompt from './views/EditPrompt';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/workflows/new',
      name: 'Create workflow',
      component: CreateWorkflow
    },
    {
      path: '/workflows/:id/edit',
      name: 'Edit workflow',
      component: EditWorkflow
    },
    {
      path: '/workflows/:id',
      name: 'Workflow',
      component: Workflow
    },
    {
      path: '/workflows/:workflowId/milestones/:milestoneId',
      name: 'Workflow editor',
      component: WorkflowEditor
    },
    {
      path: '/workflows',
      name: 'workflows',
      component: Workflows
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: Portfolio
    },
    {
      path: '/portfolio/add',
      name: 'Add Application',
      component: CreateApplication
    },
    {
      path: '/teams',
      name: 'teams',
      component: Teams
    },
    {
      path: '/teams/new',
      name: 'Create team',
      component: CreateTeam
    },
    {
      path: '/teams/:id',
      name: 'Edit Team',
      component: EditTeam
    },
    {
      path: '/people',
      name: 'people',
      component: People
    },
    {
      path: '/people/new',
      name: 'Create User',
      component: CreateUser
    },
    {
      path: '/people/:id',
      name: 'Edit User',
      component: EditUser
    },
    {
      path: '/people/:id/change-password',
      name: 'Change Password',
      component: ChangePassword
    },
    {
      path: '/people/:id/permissions',
      name: 'Permissions',
      component: Permissions
    },
    {
      path: '/people/:id/authorization',
      name: 'Manage Authorization',
      component: () => import('./views/EditAuthorization')
    },
    {
      path: '/people/:id/user-settings',
      name: 'User Settings',
      component: UserSettings
    },
    {
      path: '/specifications',
      name: 'specifications',
      component: Specifications
    },
    {
      path: '/specifications/create',
      name: 'create specification',
      component: CreateSpecification
    },
    {
      path: '/specifications/:id',
      name: 'specification',
      component: Specification
    },
    {
      path: '/specifications/:id/edit',
      name: 'edit specification',
      component: EditSpecification
    },
    {
      path: '/forms',
      name: 'forms',
      component: Forms
    },
    {
      path: '/forms/create',
      name: 'create form',
      component: CreateForm
    },
    {
      path: '/forms/:id/edit',
      name: 'edit form',
      component: EditForm
    },
    {
      path: '/uspto',
      name: 'uspto',
      component: Uspto
    },
    {
      path: '/fip',
      name: 'fip',
      component: Fip
    },
    {
      path: '/processing',
      name: 'processing',
      component: Processing
    },
    {
      path: '/applications',
      name: 'applications',
      component: Applications
    },
    {
      path: '/categorization',
      name: 'categorization',
      component: Categorization
    },
    {
      path: '/documents/word/:id/edit',
      name: 'edit word document',
      component: EditWordDocument
    },
    {
      path: '/documents/word/create',
      name: 'create word document',
      component: CreateWordDocument
    },
    {
      path: '/documents/word',
      name: 'word documents',
      component: WordDocuments
    },
    {
      path: '/documents/visio/:id/edit',
      name: 'edit visio document',
      component: EditVisioDocument
    },
    {
      path: '/documents/visio/create',
      name: 'create visio document',
      component: CreateVisioDocument
    },
    {
      path: '/documents/visio',
      name: 'visio documents',
      component: VisioDocuments
    },
    {
      path: '/drawings',
      name: 'drawings',
      component: Drawings
    },
    {
      path: '/drawings/create',
      name: 'create drawing',
      component: CreateDrawing
    },
    {
      path: '/drawings/:id/edit',
      name: 'edit drawing',
      component: EditDrawing
    },
    {
      path: '/shapes',
      name: 'shapes',
      component: () => import('./views/Shapes')
    },
    {
      path: '/shapes/:id/edit',
      name: 'edit shapes',
      component: () => import('./views/EditShape')
    },
    {
      path: '/messages',
      name: 'messages',
      component: Messages
    },
    {
      path: '/messages/create',
      name: 'create message',
      component: CreateMessage
    },
    {
      path: '/messages/:id/edit',
      name: 'edit message',
      component: EditMessage
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import('./views/Rules')
    },
    {
      path: '/rules/create',
      name: 'create rule',
      component: () => import('./views/CreateRule')
    },
    {
      path: '/rules/:id/edit',
      name: 'edit rule',
      component: () => import('./views/EditRule')
    },
    {
      path: '/organizations',
      name: 'organizations',
      component: () => import('./views/Organizations')
    },
    {
      path: '/organizations/new',
      name: 'create organization',
      component: () => import('./views/CreateOrganization')
    },
    {
      path: '/organizations/:id/edit',
      name: 'edit organization',
      component: () => import('./views/EditOrganization')
    },
    {
      path: '/administration',
      name: 'administration',
      component: () => import('./views/Administration')
    },
    {
      path: '/emails-connector',
      name: 'emails connector',
      component: () => import('./views/EmailsConnector')
    },
    {
      path: '/milestones',
      name: 'milestones',
      component: () => import('./views/Milestones')
    },
    {
      path: '/models',
      name: 'create model',
      component: CreateModelConfiguration
    },
    {
      path: '/library',
      name: 'library',
      component: Library
    },
    {
      path: '/models/:id',
      name: 'edit model',
      component: EditModelConfiguration
    },
    {
      path: '/prompts',
      name: 'prompts',
      component: Prompts
    },
    {
      path: '/prompts/create',
      name: 'create prompt',
      component: CreatePrompt
    },
    {
      path: '/prompts/:id',
      name: 'prompt',
      component: Prompt
    },
    {
      path: '/prompts/:id/edit',
      name: 'edit prompt',
      component: EditPrompt
    },
  ]
});
