<template>
  <form class="create-or-edit-prompt-form" :class="{ dirty: $v.$anyDirty }" @submit.prevent.stop="submit">
    <div>
      <p-text-field v-model.trim="name" :disabled="!$hasPermission('prompts.write')" :label="'Name'" />
      <div v-if="!$v.name.required" class="error">Name is required</div>
      <div v-if="!$v.name.minLength" class="error">Name must be at least {{ $v.name.$params.minLength.min }} characters.</div>
    </div>
    <p-checkbox v-model="isPublic" :disabled="!$hasPermission('prompts.write')" :label="'Published (visible for all users)'" />
    <div class="organizations">
      <p-checkbox v-model="isGeneric" :disabled="!$hasPermission('prompts.write')" :label="'Generic'" />
      <Multiselect
        v-model="organizations"
        :multiple="true"
        :disabled="!$hasPermission('prompts.write')"
        :searchable="true"
        :options="organizationsOptions"
        :get-label-callback="getOrganizationName"
        label="Organizations"
        placeholder="Select Organizations"
      />
    </div>
    <div class="form-row">
      <Multiselect
        v-model="categories"
        :multiple="true"
        :disabled="!$hasPermission('prompts.write')"
        :searchable="true"
        :options="categoriesOptions"
        :get-label-callback="getCategoryName"
        label="Categories"
        placeholder="Select categories"
      />
    </div>
    <div class="form-row">
      <Multiselect
        v-model="tags"
        :multiple="true"
        :disabled="!$hasPermission('prompts.write')"
        :taggable="true"
        label="Tags"
        placeholder="Select Tags"
        tag-placeholder=""
        @tag="tagAdded"
      />
    </div>
    <div>
      <p-text-field v-model="description" :disabled="!$hasPermission('prompts.write')" :label="'Description'" :multiline="true" :rows="3" />
    </div>
    <div v-if="isRequestFailed" class="error">Failed to save prompt. Check your input and try again.</div>
    <div>
      <p-button type="button" @click.prevent="onCancel">Cancel</p-button>
      <p-button v-if="$hasPermission('prompts.write')" color="primary" type="submit" :disabled="isRequestPending">Save</p-button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';

import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import { ca } from 'date-fns/locale';
export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-checkbox': Checkbox,
    Multiselect
  },
  props: {
    item: {
      type: Object,
      default: () => null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: this.item ? this.item.name : '',
      isPublic: this.item ? this.item.isPublic : false,
      isGeneric: this.item ? this.item.isGeneric : false,
      organizations: this.item?.organizations ?? [],
      description: this.item ? this.item.description : '',
      tags: this.item ? this.item.tags : [],
      categories: this.item ? this.item.categories : []
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    }
  },
  computed: {
    ...mapState({
      isCreateRequestPending: s => s.prompts.isCreateRequestPending,
      isCreateRequestFailed: s => s.prompts.isCreateRequestFailed,
      organizationsCollection: s => s.organizations.collection,
      categoriesCollection: s => s.library.categories
    }),
    organizationsOptions() {
      return this.organizationsCollection.map(o => o.id);
    },
    categoriesOptions() {
      return this.categoriesCollection.map(o => o.id);
    }
  },
  async created() {
    await Promise.all([this.$store.dispatch('organizations/getCollection'), this.$store.dispatch('library/getCategories')]);
  },
  methods: {
    getOrganizationName(value) {
      return this.organizationsCollection.find(o => o.id === value)?.name ?? 'Unknown Organization';
    },
    getCategoryName(value) {
      const option = this.categoriesCollection.find(o => o.id === value);
      if (!option) {
        return 'Unknown Category';
      }
      if (option.parentId) {
        const parent = this.categoriesCollection.find(o => o.id === option.parentId);
        if (!parent) {
          return option.name;
        }
        return `${parent.name} > ${option.name}`;
      }
      return option.name;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('submit', {
        id: this.item ? this.item.id : null,
        name: this.name,
        isPublic: this.isPublic,
        isGeneric: this.isGeneric,
        organizations: this.organizations,
        categories: this.categories,
        description: this.description,
        tags: this.tags,
        template: this.item ? this.item.template : {}
      });
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    },

    organizationAdded(organization) {
      if (!Array.isArray(this.organizations)) {
        this.organizations = [];
      }

      if (this.organizations.includes(organization)) {
        return;
      }
      this.organizations.push(organization);
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-prompt-form {
  padding: 2rem;
  max-width: 768px;
  height: 100%;
  background: var(--theme-surface);
  overflow-y: auto;

  .organizations {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
  }

  .form-row {
    &.code {
      height: 50%;
    }
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }

  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
