<template>
  <section v-if="isGetRequestPending">
    <p-loading />
  </section>
  <section v-else-if="item.name" class="prompts" :class="{ settings: settingsShown }">
    <PromptContent ref="promptContent" :item="item" :show-settings="settingsShown" @submit="save" @cancelEditingSettings="settingsShown = false" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';

import PromptContent from './PromptContent.vue';
export default {
  components: {
    'p-loading': Loading,
    PromptContent,

  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      settingsShown: false,
      itemId: this.id || this.$route.params.id
    };
  },
  computed: {
    ...mapState({
      item: s => s.prompts.item,
      isGetRequestPending: s => s.prompts.isGetRequestPending,
      organizationsCollection: s => s.organizations.collection ?? [],
      categoriesCollection: s => s.library.categories ?? []
    }),
    organizationsNames() {
      if (!this.item || !this.organizationsCollection) {
        return [];
      }

      const organizations = this.item.organizations
        ? this.item.organizations.map(id => this.organizationsCollection.find(o => o.id === id)?.name ?? id)
        : [];

      if (!organizations.length) {
        return [];
      }

      return organizations;
    },
    categoriesNames() {
      if (!this.item || !this.categoriesCollection) {
        return [];
      }

      const categories = this.item.categories
        ? this.item.categories.map(id => {
            const category = this.categoriesCollection.find(c => c.id === id);
            if (category.parentId) {
              const parent = this.categoriesCollection.find(c => c.id === category.parentId)?.name ?? category.parentId;
              return `${parent} > ${category.name}`;
            }
            return category.name;
          })
        : [];
      if (!categories.length) {
        return [];
      }

      return categories;
    }
  },
  watch: {
    id() {
      this.initialize();
    }
  },
  async created() {
    this.initialize();
  },
  methods: {
    async readyToLeave() {
      if (!this.$refs.promptContent.hasChanges) {
        return true;
      }

      return await this.$confirm({
        title: 'You have unsaved changes.',
        message: `Are you sure you want to leave page? All changes will be lost.`,
        confirm: 'Leave anyway'
      });
    },
    async initialize() {
      await Promise.all([
        this.$store.dispatch('nlp/getConfigurations'),
        this.$store.dispatch('prompts/getById', this.itemId),
        this.$store.dispatch('organizations/getCollection'),
        this.$store.dispatch('library/getCategories')
      ]);
    },
    selectTemplate(id) {
      this.$router.push({ path: `/prompts/${id}` });
    },
    async save(payload) {
      await this.$store.dispatch('prompts/update', {
        ...payload
      });
      this.settingsShown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.prompts-wrapper {
  width: 100%;
  height: 100%;
}

.prompts {
  width: 100%;
  margin: 0 0.5rem;
  display: grid;
  grid-template-rows:  minmax(0, 1fr);
  grid-gap: 0.5rem;
  height: 100%;

  &.settings {
    grid-template-rows: max-content max-content max-content;
  }

  > header {
    background: var(--theme-surface);
    padding: 0.2rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .prompt-name-wrapper {
      grid-template-rows: auto auto;
      padding: 7px;
      align-items: flex-start;

      .prompt-name {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--theme-on-background-dark);
        padding: 0 5px;
        font-size: 1rem;
        cursor: pointer;
      }

      .button {
        padding: 0;
      }
    }

    .prompt-organization {
      display: flex;
      flex-wrap: wrap;
      padding-left: 25px;

      .organization {
        display: flex;
        align-items: center;
        background-color: var(--theme-on-surface);
        color: black;
        margin-top: 2px;
        padding: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.65rem;
        margin-right: 5px;
        margin-bottom: 2px;
        border-radius: 2px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        user-select: none;
        cursor: auto;
        &.category {
          background-color: #5d4ce8;
        }

        &.public {
          background-color: var(--theme-primary);
          color: var(--theme-on-primary);
        }
      }
    }
  }
}
</style>
