<template>
  <div class="library-wrapper">
    <Categories :selected="selected" @selected="onSelected" />
    <div class="entity-wrapper">
      <Entity v-if="selected" :item="selected" @cancel="selected = null" />
    </div>
  </div>
</template>
<script>
import Categories from './Categories.vue';
import Entity from './Entity.vue';
export default {
  components: {
    Categories,
    Entity
  },
  data() {
    return {
      selected: null
    };
  },
  async created() {
    if (this.$route.query.id) {
      const item = await this.$store.dispatch('library/getById', this.$route.query.id);
      this.selected = item;
    }
  },
  methods: {
    onSelected(item) {
      this.selected = item;
      this.$router.push({ query: { id: item.id } });
    }
  }
};
</script>
<style lang="scss" scoped>
.library-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 400px 1fr;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  .entity-wrapper {
    width: 100%;
    height: 100%;
  }
}
</style>
