<template>
  <div class="category-element" :class="{ selected: isSelected }" :title="item.description" @click="$emit('selected')">
    <div class="title">
      <MdIcon v-if="item.libraryType === 'drawing'" name="drawing-box" />
      <MdIcon v-if="item.libraryType === 'prompt'" name="account-voice" />
      <MdIcon v-if="item.libraryType === 'specification'" name="text-box-multiple-outline" />
      <div>{{ item.name }}</div>
    </div>
    <div class="desc">{{ desc }}</div>
  </div>
</template>

<script>
import MdIcon from '@/components/common/MdIcon.vue';
export default {
  components: {
    MdIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selected'],
  computed: {
    desc() {
      const author = this.item.updatedBy || this.item.createdBy;
      const splitted = author.split('@');
      const date = new Date(this.item.updatedAt || this.item.createdAt).toLocaleString();
      return `${splitted[0]} ${date}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.category-element {
  padding: 5px;
  margin: 10px 0;

  &.selected {
    background-color: #5d4ce8;
  }

  .title {
    display: grid;
    font-size: 0.85rem;
    grid-template-columns: 30px 1fr;
  }
  .desc {
    font-size: 10px;
    font-style: italic;
  }
}
/* Your component's CSS styles go here */
</style>
