<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-drawing-form" @submit.prevent="submit">
    <div class="left">
      <div class="form-row code">
        <p-code v-model="textTemplate" :read-only="!$hasPermission('templates.write')" :language="'handlebars'"></p-code>
      </div>
    </div>
    <div class="right">
      <div class="form-row template">
        <p-file
          :label="'Template file'"
          :disabled="!$hasPermission('templates.write')"
          :value="fileTemplate"
          accept=".vsdx,.vstx"
          @input="templateSelected"
        ></p-file>
      </div>
      <div class="form-row name">
        <p-text-field v-model="name" :label="'Name'" autocomplete="off" :disabled="true" />
        <div v-if="!$v.name.required" class="error">Template is required</div>
      </div>
      <div class="form-row description">
        <p-text-field v-model="description" :disabled="!$hasPermission('templates.write')" :label="'Description'" autocomplete="off" />
      </div>
      <div class="form-row tags">
        <p-multiselect
          v-model="tags"
          :multiple="true"
          :taggable="true"
          label="Tags"
          placeholder=""
          :disabled="!$hasPermission('templates.write')"
          tag-placeholder=""
          @tag="tagAdded"
        ></p-multiselect>
      </div>
      <div v-if="isRequestFailed" class="error">
        Failed to save a drawing. Check your input and try again.
      </div>
      <div class="form-row submit">
        <p-button v-if="$hasPermission('templates.write')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import File from '@/components/common/File';
import Code from '@/components/common/Code';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-file': File,
    'p-multiselect': Multiselect,
    'p-code': Code
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        name: this.item.name,
        ext: this.item.ext,
        description: this.item.description,
        textTemplate: this.item.textTemplate,
        fileTemplate: null,
        tags: this.item.tags
      };
    }
    return {
      name: '',
      ext: '',
      description: '',
      fileTemplate: null,
      textTemplate: '',
      tags: []
    };
  },
  validations: {
    name: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        name: this.name && this.name.trim(),
        ext: this.ext,
        description: this.description && this.description.trim(),
        fileTemplate: this.fileTemplate,
        textTemplate: this.textTemplate,
        tags: this.tags
      });

      this.$v.$reset();
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    },
    templateSelected(template) {
      const [name, ...ext] = template.name.split('.');
      this.name = name;
      this.ext = '.' + ext.join('.');

      this.fileTemplate = template;
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-drawing-form {
  height: 100%;
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  display: grid;

  > div {
    min-width: 0;
    min-height: 0;
  }
  .left {
    grid-column: 2/3;
    grid-row: 1/2;

    background: var(--theme-surface);
    padding: 0.1rem;
    overflow: hidden;

    .code {
      height: 100%;
    }
  }
  .right {
    grid-column: 1/2;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;

    > div {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .submit {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  // > * {
  //   &:not(:last-child) {
  //     margin-bottom: 1.5rem;
  //   }
  //   &:last-child {
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  // }

  // .form-row {
  //   &.template {
  //     grid-column: 1/2;
  //     grid-row: 1/2;
  //   }
  //   &.name {
  //     grid-column: 1/2;
  //     grid-row: 2/3;
  //   }
  //   &.description {
  //     grid-column: 1/2;
  //     grid-row: 3/4;
  //   }
  //   &.tags {
  //     grid-column: 1/2;
  //     grid-row: 4/5;
  //   }

  //   &.code {
  //     grid-column: 2/3;
  //     grid-row: 1/5;
  //   }
  //   &.submit {
  //     grid-column: 1/3;
  //     grid-row: 5/6;
  //   }
  // }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
