<template>
  <section class="list-wrapper">
    <div class="header-wrapper">
      <div class="search">
        <p-text-field :value="searchText" label="" placeholder="Type to search.." @input="updateSearchText" />
      </div>
      <div class="filter">
        <slot name="filter"></slot>
      </div>
      <div>Sort by:<p-dropdown :value="sort" :options="options.sorting" @change="setSort"></p-dropdown></div>
      <div><label>Total:</label>&nbsp;{{ total }}</div>
    </div>
    <ul class="list">
      <li :class="['list-item', 'header', 'li-' + _uid]">
        <div v-for="item of $$metadata" :key="item.name">{{ item.label }}</div>
        <div></div>
      </li>
    </ul>
    <ul :id="name + '-list'" class="list">
      <li v-for="row in $$data" :key="row.id" :class="['list-item', 'li-' + _uid]">
        <div v-for="(column, index) in $$metadata" :key="index" :class="[typeof row[column.name] === 'boolean' ? 'boolean' : '']">
          <slot :name="column.name" :value="row[column.name]" :item="row" :settings="column">
            <p-tag-collection
              v-if="Array.isArray(row[column.name])"
              :value="row[column.name]"
              :get-label-callback="getCallback(column.name)"
            ></p-tag-collection>
            <div v-else-if="typeof row[column.name] === 'boolean'">
              <Icon v-if="row[column.name]" name="checked" />
            </div>
            <span v-else-if="column.name === 'createdAt'">
              <span v-if="row.createdBy">{{ new Date(row.createdAt).toLocaleString() }} by {{ row.createdBy }}</span>
              <span v-else></span>
            </span>
            <span v-else-if="column.name === 'updatedAt'">
              <span v-if="row.updatedAt && row.updatedBy">{{ new Date(row.updatedAt).toLocaleString() }} by {{ row.updatedBy }}</span>
              <span v-else></span>
            </span>
            <span v-else-if="column.name === 'modifiedAt'">
              <div v-if="row.updatedAt && row.updatedBy">
                <span>{{ new Date(row.updatedAt).toLocaleString() }} by {{ row.updatedBy }}</span>
              </div>
              <div v-else-if="row.createdBy">
                <span>{{ new Date(row.createdAt).toLocaleString() }} by {{ row.createdBy }}</span>
              </div>
            </span>
            <span v-else>{{ row[column.name] }}</span>
          </slot>
        </div>

        <div class="list-item-actions">
          <slot name="__actions" :item="row">
            <p-button v-for="action of actions" :key="action.name" variant="text" :title="action.tooltip" @click="$emit(action.name, row)">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="action.label"></span>
            </p-button>
          </slot>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import Button from './../common/Button';
import TextField from './../common/TextField';
import Dropdown from '@/components/common/Dropdown';
import Icon from '@/components/common/Icon';
import TagCollection from './../common/TagCollection';
import { debounce } from '@/utils/debounce';
import { lw } from '@/utils/lw';

export default {
  components: {
    'p-button': Button,
    'p-dropdown': Dropdown,
    'p-text-field': TextField,
    'p-tag-collection': TagCollection,
    Icon
  },
  props: {
    name: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      default: () => [
        { name: 'edit', label: '&#9998;', tooltip: 'Edit this item' },
        { name: 'delete', label: '&times;', tooltip: 'Delete this item' }
      ]
    }
  },
  data() {
    return {
      searchText: '',
      sort: lw.get(`LOCAL_STORAGE_${this.name.toUpperCase()}_SORT_KEY_`) || '',

      options: {
        sorting: [
          { id: '', label: 'Name' },
          { id: 'newest', label: 'Newest' },
          { id: 'oldest', label: 'Oldest' }
        ]
      }
    };
  },
  computed: {
    $$metadata() {
      return Object.keys(this.metadata).map(name => ({ name, ...this.metadata[name] }));
    },
    $$data() {
      let f;
      switch (this.sort) {
        case 'newest':
          f = (a, b) => new Date(b.updatedAt || b.createdAt || b.modifiedAt) - new Date(a.updatedAt || a.createdAt || a.modifiedAt);
          break;
        case 'oldest':
          f = (a, b) => new Date(a.updatedAt || a.createdAt || a.modifiedAt) - new Date(b.updatedAt || b.createdAt || b.modifiedAt);
          break;
        default:
          f = (a, b) => a.name?.localeCompare(b.name);
          break;
      }

      const filter = item => {
        if (!this.searchText || !this.searchText.length) {
          return true;
        }

        return Object.values(item)
          .map(item => (Array.isArray(item) ? item.join('') : item))
          .join('')
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      };

      return [...this.data].filter(filter).sort(f);
    }
  },
  watch: {
    $$metadata: {
      handler() {
        const innerHTML = `
            .list-item.li-${this._uid} {
                grid-template-columns: ${this.$$metadata.reduce((result, current) => result + ' ' + current.width, '')} 100px !important;
            }
        `;

        const id = `list-item-${this._uid}`;
        if (document.getElementById(id) && document.getElementById(id).innerHTML === innerHTML) {
          return;
        }

        const el = document.createElement('style');
        el.setAttribute('id', id);
        el.innerHTML = innerHTML;
        document.head.appendChild(el);
      },
      deep: true,
      immediate: true
    }
  },
  destroyed() {
    const id = `list-item-${this._uid}`;
    this.$nextTick(() => {
      const el = document.getElementById(id);
      el.parentElement.removeChild(el);
    });
  },
  methods: {
    getCallback(item) {
      return this.metadata[item]?.labelCallback ?? undefined;
    },
    updateSearchText: debounce(function(value) {
      this.searchText = value;
    }, 250),
    setSort(id) {
      const option = this.options.sorting.find(option => option.id === id);
      if (!option) {
        return;
      }
      lw.set(`LOCAL_STORAGE_${this.name.toUpperCase()}_SORT_KEY_`, id);

      this.sort = id;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: minmax(0, max-content) minmax(0, max-content) minmax(0, 1fr);
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--theme-surface);
  padding: 1rem;
  position: relative;
  .header-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    font-weight: 400;
    margin-right: 6px;
    padding-bottom: 1rem;
    line-height: 1;
    white-space: nowrap;
    label {
      font-weight: 500;
    }

    > * {
      height: 100%;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    .filter {
      > * {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }

    .search {
      margin-right: auto;
      width: 250px;
      height: 20px;
      position: relative;
      margin-top: 5px;

      > div {
        position: absolute;
        top: 50%;
        transform: translateY(-17px);
        width: 100%;
      }
    }
  }
  .list {
    margin: 0;
    padding: 0;
    list-style: none;

    .list-item {
      display: grid;
      //   grid-template-columns: 200px 300px minmax(0, 1fr) 175px 175px 100px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;
      min-height: 1.75rem;
      align-items: center;

      .boolean {
        justify-content: center;
      }

      > * {
        border-left: 1px solid var(--theme-highlight);
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: pre-line;
        overflow: hidden;

        > label {
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          width: 100%;
        }

        &:last-child {
          border-left: 1px solid transparent;
          border-right: 1px solid var(--theme-highlight);
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight);
      }

      &:hover {
        background-color: var(--theme-highlight);
        .list-item-actions {
          display: flex;
          justify-content: flex-end;
        }
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 0.5rem;
        }
      }

      .list-item-actions {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
