import { render, staticRenderFns } from "./MdIcon.vue?vue&type=template&id=39f1b983&scoped=true&"
import script from "./MdIcon.vue?vue&type=script&lang=js&"
export * from "./MdIcon.vue?vue&type=script&lang=js&"
import style0 from "./MdIcon.vue?vue&type=style&index=0&id=39f1b983&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f1b983",
  null
  
)

export default component.exports