<template>
  <div class="toggle-switch">
    <div v-for="option in options" :key="option" class="option" :class="{ selected: option === value }" @click="$emit('input', option)">
      {{ option }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: this.value
    };
  },
  methods: {
    toggle() {
      this.$emit('change', this.isChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
.option {
  display: inline-block;
  padding: 3px 7px;
  cursor: pointer;
  font-size: 12px;
  background-color: #eceaff;
  color: #343434;

  &.selected {
    background-color: #5d4ce8;
    color: #fcfcfc;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
</style>
