<template>
  <div class="categories-wrapper">
    <div class="filter-holder">
      <ToggleOptions v-model="filter.groupBy" :options="['category', 'date']" @input="toggleGroupby" />
      <CategoriesFilter :filter="filter" @updated="onFilterUpdated" />
    </div>
    <div class="search-input-wrapper">
      <SearchInput v-model="filter.name" @input="onSearchChange" />
    </div>
    <div class="categories-list-wrapper">
      <div class="buttons-wrapper">
        <div>
          <Button v-if="filter.groupBy !== 'date'" @click="addCategory()">
            <MdIcon name="plus" />
            Add category
          </Button>
        </div>
        <div>
          <Button variant="text" @click="createEntity('drawing')">
            <MdIcon color="success" name="drawing-box" />
          </Button>
          <Button variant="text" @click="createEntity('prompt')">
            <MdIcon color="success" name="account-voice" />
          </Button>
          <Button variant="text" @click="createEntity('specification')">
            <MdIcon color="success" name="text-box-multiple-outline" />
          </Button>
        </div>
      </div>
      <draggable
        :list="list"
        group="category"
        :disabled="filter.groupBy === 'date'"
        ghost-class="ghost"
        class="dnd"
        :class="{ dragging: isDraggingItem }"
        handle=".category-drag-handle"
        @change="onChange"
      >
        <div v-for="category in list" :key="category.id" class="category-item">
          <CategoryHeader
            :handle-classes="['category-drag-handle']"
            class="header"
            :category="category"
            :expanded="expandedCategories.includes(category.id)"
            :count="filter.groupBy === 'category' ? getCategoryStat(category.id) : null"
            :show-actions="category.id !== 'other' && filter.groupBy !== 'date'"
            @expanded="toggleCategoryExpanded(category)"
            @action="$e => onCategoryAction($e, category)"
          />
          <template v-if="expandedCategories.includes(category.id)">
            <draggable
              :list="category.categories"
              :disabled="filter.groupBy === 'date'"
              class="subcategory"
              group="subcategory"
              handle=".subcategory-drag-handle"
              :count="getCategoryStat(category.id)"
              @change="$e => onChange($e, category)"
            >
              <div v-for="subcategory in category.categories" :key="subcategory.id" class="subcategory">
                <CategoryHeader
                  :handle-classes="['subcategory-drag-handle']"
                  :category="subcategory"
                  :expanded="expandedCategories.includes(subcategory.id)"
                  :count="getCategoryStat(subcategory.id)"
                  :show-actions="category.id !== 'other' && filter.groupBy !== 'date'"
                  @expanded="toggleCategoryExpanded(subcategory)"
                  @action="$e => onCategoryAction($e, subcategory)"
                />
                <draggable
                  v-if="expandedCategories.includes(subcategory.id)"
                  :list="subcategory.items"
                  group="item"
                  :disabled="filter.groupBy === 'date'"
                  class="ololo"
                  :sort="false"
                  @change="$e => onItemChange($e, subcategory)"
                  @start="onDragItemStart"
                  @end="onDragItemEnd"
                >
                  <div v-for="item in subcategory.items" v-show="!isDraggingItem" :key="item.id" class="item">
                    <CategoryItem :item="item" :is-selected="selected && item.id === selected.id" @selected="onItemSelected(item)" />
                  </div>

                  <div
                    v-show="isDraggingItem"
                    :key="`dropzone_${subcategory.id}`"
                    :class="{ target: dragover === `dropzone_${subcategory.id}` }"
                    class="dropzone"
                    @dragover="setDrag(`dropzone_${subcategory.id}`)"
                    @drugleave="setDrag(null)"
                  >
                    Add to "{{ subcategory.name }}"
                  </div>
                </draggable>
              </div>
            </draggable>
            <draggable
              :list="category.items"
              group="item"
              class="standalone"
              :sort="false"
              @change="$e => onItemChange($e, category)"
              @start="onDragItemStart"
              @end="onDragItemEnd"
            >
              <div v-for="item in category.items" v-show="!isDraggingItem" :key="item.id">
                <CategoryItem :item="item" :is-selected="selected && item.id === selected.id" @selected="onItemSelected(item)" />
              </div>
              <div
                v-show="isDraggingItem"
                :key="`dropzone_${category.id}`"
                class="dropzone"
                :class="{ target: dragover === `dropzone_${category.id}` }"
                @dragover="setDrag(`dropzone_${category.id}`)"
                @drugleave="setDrag(null)"
              >
                Add to "{{ category.name }}"
              </div>
            </draggable>
          </template>
        </div>
      </draggable>
    </div>

    <CreateOrEditCategory v-if="editedCategory" :category="editedCategory" @close="editedCategory = null" @submit="submitCategory" />
  </div>
</template>

<script>
import SearchInput from './SearchInput.vue';
import draggable from 'vuedraggable';
import CategoryHeader from './CategoryHeader.vue';
import CategoryItem from './CategoryItem.vue';
import Button from '@/components/common/Button.vue';
import MdIcon from '@/components/common/MdIcon.vue';
import CreateOrEditCategory from './CreateOrEditCategory.vue';
import CategoriesFilter from './Filter.vue';
import { mapState } from 'vuex';
import { debounce } from '@/utils/debounce';
import ToggleOptions from './ToggleOptions.vue';
export default {
  components: {
    SearchInput,
    draggable,
    CategoryHeader,
    CategoryItem,
    Button,
    MdIcon,
    CreateOrEditCategory,
    CategoriesFilter,
    ToggleOptions
  },
  props: {
    selected: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isDraggingItem: false,
      expandedCategories: [],
      expandedSubcategories: [],
      editedCategory: null,
      currentlyEditedItem: null,
      dragover: null,
      filter: {
        groupBy: 'category',
        name: '',
        organization: [],
        author: [],
        type: []
      }
    };
  },
  computed: {
    ...mapState({
      categories: state => state.library.categories,
      items: state => state.library.collection,
      statistics: state => state.library.statistics
    }),
    list() {
      if (this.filter.groupBy === 'date') {
        return this.dateTree;
      }
      return this.categoriesTree;
    },
    dateTree() {
      if (!this.statistics.last_modified_at) {
        return [];
      }
      const response = [];
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      const thisMonthValue = this.statistics.last_modified_at.find(d => d._id.month === currentMonth + 1 && d._id.year === currentYear);
      if (thisMonthValue) {
        const id = `${currentYear}-${currentMonth}`;
        let items = [];
        if (this.expandedCategories.includes(id)) {
          items = this.items;
        }
        response.push({
          name: `Current month(${thisMonthValue.count})`,
          items,
          categories: [],
          id,
          filter: {
            from: new Date(currentYear, currentMonth, 1),
            to: new Date(currentYear, currentMonth + 1, 0, 23, 59, 59)
          }
        });
      }

      for (let i = months.length - 1; i > -1; i--) {
        if (i >= currentMonth) {
          continue;
        }
        const monthValue = this.statistics.last_modified_at.find(d => d._id.month === i + 1 && d._id.year === currentYear);
        if (monthValue) {
          const id = `${currentYear}-${i}`;
          let items = [];
          if (this.expandedCategories.includes(id)) {
            items = this.items;
          }
          response.push({
            name: `${months[i]}(${monthValue.count})`,
            items,
            categories: [],
            id,
            filter: {
              from: new Date(currentYear, i, 1),
              to: new Date(currentYear, i + 1, 0, 23, 59, 59)
            }
          });
        }
      }

      const years = this.statistics.last_modified_at.reduce((acc, curr) => {
        if (curr._id.year === currentYear) {
          return acc;
        }
        if (!acc[curr._id.year] && curr._id.year !== currentYear) {
          acc[curr._id.year] = 0;
        }
        acc[curr._id.year] += curr.count;
        return acc;
      }, {});

      Object.entries(years)
        .sort(([year1], [year2]) => year2 - year1)
        .forEach(([year, count]) => {
          let items = [];
          const id = year;
          if (this.expandedCategories.includes(id)) {
            items = this.items;
          }
          response.push({
            name: `${year}(${count})`,
            items,
            categories: [],
            id,
            filter: {
              from: new Date(year, 0, 1),
              to: new Date(year, 11, 31, 23, 59, 59)
            }
          });
        });
      return response;
    },
    categoriesTree() {
      if (!this.categories?.length) {
        return [];
      }
      const addedItems = [];

      const createCategory = category => {
        const children = this.categories.filter(child => child.parentId === category.id);
        let items;
        if (category.id === 'other') {
          items = [];
        } else {
          items = this.items.filter(item => (item.categories || []).includes(category.id)).sort((a, b) => a.name.localeCompare(b.name));
        }
        addedItems.push(...items);
        return {
          ...category,
          items,
          categories: children.map(createCategory).sort((a, b) => a.order - b.order)
        };
      };
      const rootCategories = this.categories.filter(category => !category.parentId);
      const tree = rootCategories
        .sort((a, b) => a.order - b.order)
        .map(category => {
          return createCategory(category);
        });
      const other = tree.find(c => c.id === 'other');
      if (other) {
        other.items = this.items.filter(item => !addedItems.includes(item));
      }

      return tree;
    }
  },

  async created() {
    await this.$store.dispatch('library/getCategories');
    await this.$store.dispatch('library/getStats');
    await this.$store.dispatch('library/getCollection', this.filter);
  },
  methods: {
    async toggleCategoryExpanded(category) {
      if (this.expandedCategories.includes(category.id)) {
        this.expandedCategories = this.expandedCategories.filter(expandedId => expandedId !== category.id);
      } else {
        if (this.filter.groupBy === 'date') {
          this.expandedCategories = [category.id];
          await this.$store.dispatch('library/getCollection', { ...this.filter, from: category.filter.from, to: category.filter.to });
        } else {
          this.expandedCategories.push(category.id);
          await this.$store.dispatch('library/getCollection', { ...this.filter, categories: this.expandedCategories });
        }
      }
    },
    onItemSelected(item) {
      this.$emit('selected', item);
    },
    addCategory(parent) {
      this.editedCategory = {
        name: 'new category',
        description: 'for awesome purposes',
        parent
      };
    },
    async onCategoryAction(option, category) {
      if (option === 'edit') {
        this.editedCategory = {
          ...category
        };
      } else if (option === 'delete') {
        await this.$store.dispatch('library/deleteCategory', category.id);
      } else if (option === 'add-subcategory') {
        this.addCategory(category);
      }
    },
    submitCategory(category) {
      const editing = category.id;
      let order = category.order;
      if (!editing) {
        if (this.editedCategory.parent) {
          order = this.editedCategory?.parent?.categories?.length;
        } else {
          order = this.categories.length - 1;
        }
      }
      this.$store.dispatch('library/createCategory', { ...category, order, parentId: this.editedCategory.parent?.id });
      this.editedCategory = null;
    },
    applyFilter() {
      if (this.expandedCategories.length > 0) {
        this.$store.dispatch('library/getCollection', { ...this.filter, category: this.expandedCategories });
      }
      this.$store.dispatch('library/getStats', this.filter);
    },
    onSearchChange: debounce(function() {
      if (this.filter.name.length > 0 && this.filter.name.length < 3) {
        return;
      }
      this.applyFilter();
    }, 300),
    onFilterUpdated(filter) {
      this.filter = filter;
      this.applyFilter();
    },
    getCategoryStat(id) {
      if (!this.statistics.category) {
        return 0;
      }
      const parentCount = this.statistics.category.find(u => u._id === id)?.count || 0;
      const c = this.categories.find(c => c.id === id);
      const children = c.categories || [];

      return children.reduce((acc, curr) => {
        return acc + (this.statistics.category.find(u => u._id === curr.id)?.count || 0);
      }, parentCount);
    },
    async toggleGroupby(groupBy) {
      this.filter.groupBy = groupBy;
      this.expandedCategories = [];
      await this.applyFilter();
    },
    async onChange(event, parent) {
      // change parrent category has 2 steps
      // 1 event.added - to new parent
      // 2 event.removed - from old parent

      // we gather batch of events and update them all at once
      const promises = [];
      if (event.added) {
        promises.push(
          ...parent.categories.map((category, index) =>
            category.id === 'other'
              ? Promise(resolve)
              : this.$store.dispatch('library/createCategory', { ...category, order: index, parentId: parent.id })
          )
        );
      }
      if (event.moved) {
        const { element } = event.moved;
        const list = element.parentId ? this.list.find(c => c.id === element.parentId).categories : this.list;
        const clone = [...list];
        promises.push(
          ...clone.map((category, index) =>
            category.id === 'other' ? Promise(resolve) : this.$store.dispatch('library/createCategory', { ...category, order: index })
          )
        );
      }
      if (event.removed) {
        promises.push(
          ...parent.categories.map((category, index) =>
            category.id === 'other'
              ? Promise(resolve)
              : this.$store.dispatch('library/createCategory', { ...category, order: index, parentId: parent.id })
          )
        );
      }
      await Promise.all(promises);
      this.$store.dispatch('library/getStats', this.filter);
    },
    setDrag(e) {
      this.dragover = e;
    },
    async onItemChange(event, parent) {
      const isFirstUpdate = !this.currentlyEditedItem;
      const target = this.currentlyEditedItem || event.added.element || event.removed.element;
      if (event.added) {
        target.categories = [...target.categories, parent.id];
        this.currentlyEditedItem = target;
      }
      if (event.removed) {
        target.categories = target.categories.filter(c => c !== parent.id);
        this.currentlyEditedItem = target;
      }
      if (isFirstUpdate) {
        return;
      }

      const libraryType = target.libraryType;
      await this.$store.dispatch(`${libraryType}s/update`, target);
      await this.$store.dispatch('library/getStats', this.filter);
      this.currentlyEditedItem = null;
    },
    onDragItemStart() {
      this.isDraggingItem = true;
      this.currentExpanded = [...this.expandedCategories];
      this.expandedCategories = [...this.categories.map(c => c.id)];
    },
    onDragItemEnd() {
      this.isDraggingItem = false;
      this.expandedCategories = this.currentExpanded;
    },
    createEntity(libraryType) {
      const common = {
        libraryType,
        name: `new ${libraryType}`,
        isPublic: false,
        isGeneric: false,
        organizations: [],
        description: `Great ${libraryType} for you`,
        tags: [],
        categories: []
      };
      let entitySpecific = {};

      if (libraryType === 'prompt') {
        entitySpecific = {
          template: []
        };
      }
      if (libraryType === 'drawing') {
        entitySpecific = {
          fileTemplate: null,
          textTemplate: null
        };
      }
      if (libraryType === 'specification') {
        entitySpecific = {
          template: []
        };
      }

      this.$emit('selected', { ...common, ...entitySpecific });
    }
  }
};
</script>

<style lang="scss" scoped>
.categories-wrapper {
  padding: 0 20px;
  overflow: hidden;
  display: grid;
  grid-template-rows: max-content max-content 1fr;

  .dropzone {
    border: 1px dashed var(--theme-on-background-accent);
    padding: 10px;
  }

  .dnd {
    &.dragging {
      .dropzone {
        &.target {
          background-color: #5d4ce8;
        }
      }
    }
  }

  .ghost {
    background-color: green;
  }
  .filter-holder {
    margin-bottom: 20px;
  }
  .search-input-wrapper {
    margin-bottom: 20px;
  }
  .categories-list-wrapper {
    overflow-y: scroll;
    .buttons-wrapper {
      justify-content: space-between;
      display: flex;
      align-items: center;
    }
  }
  .category-item {
    border-bottom: 1px solid var(--theme-on-background-accent);
    .header {
      background-color: var(--theme-surface);
      padding: 10px;
    }

    .subcategory {
      padding-left: 5px;
    }
    .item {
      padding-left: 10px;
    }
    .standalone {
      padding-left: 10px;
    }
  }
}
</style>
