<template>
  <div class="hidden-filter">
    <span v-for="item in items" :key="item.id" class="tag" @click="$emit('toggled', item.id)">{{ item.name }}({{ item.count }})</span>
    <span v-if="filteredCollection.length > 5" class="toggle-more" @click="collapsed = !collapsed">{{ collapsed ? 'more' : 'less' }}</span>
  </div>
</template>

<script>
export default {
  name: 'HiddenFilter',
  props: {
    collection: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    mapping: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      collapsed: true
    };
  },
  computed: {
    filteredCollection() {
      return this.collection
        .filter(c => !this.selected.includes(c.id))
        .map(c => {
          const mapping = this.mapping.find(m => m._id === c.id);
          return { ...c, count: mapping?.count };
        })
        .filter(c => c.count > 0)
        .sort((a, b) => b.count - a.count);
    },
    items() {
      if (!this.mapping) {
        return [];
      }
      if (this.collapsed) {
        return this.filteredCollection.slice(0, 5);
      } else {
        return this.filteredCollection;
      }
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.toggle-more {
  cursor: pointer;
  font-size: 12px;
  color: #5d4ce8;
}
</style>
