<template>
  <div class="formpicker-wrapper">
    <Multiselect
      :multiple="true"
      :taggable="false"
      label="Forms"
      :value="forms"
      :get-label-callback="getLabelCallback"
      :options="formsOptions"
      placeholder="Select default forms"
      @input="$emit('formsChanged', $event)"
    />
    <Multiselect
      :multiple="true"
      :taggable="true"
      label="Tags"
      :value="tags"
      :options="tagsOptions"
      placeholder="Select tags"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import Multiselect from '@/components/common/Multiselect';
import { mapState } from 'vuex';
export default {
  components: {
    Multiselect
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    forms: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      aggs: s => s.forms.aggs,
      formsCollection: s => s.forms.collection
    }),
    tagsOptions() {
      if (!this.aggs) {
        return [];
      }
      return Object.keys(this.aggs.tags).filter(t => t);
    },
    formsOptions() {
      if (!this.formsCollection) {
        return [];
      }
      return this.formsCollection.map(f => f.id);
    }
  },
  async created() {
    await this.$store.dispatch('forms/getCollection');
  },
  methods: {
    getLabelCallback(id) {
      const formName = this.formsCollection.find(f => f.id == id)?.name;
      if (formName) {
        return formName;
      }
      return id;
    }
  }
};
</script>
