import { am } from './utils';

import httpClient, { jsonObjectToFormData } from '@/utils/httpClient';

const REINDEX = am(`REINDEX`);
const RE_TAG = am(`RE_TAG`);
const PRUNE_TAGS = am(`PRUNE_TAGS`);
const IMPORT_BACKUP = am(`IMPORT_BACKUP`);
const GET_IMPORT_COLLECTION = am('GET_IMPORT_COLLECTION');
const GET_EXPORT_URL = am('GET_EXPORT_URL');
const GET_SETTINGS = am('GET_SETTINGS');
const UPDATE_SETTINGS = am('UPDATE_SETTINGS');

export default {
  namespaced: true,
  state: {
    isReIndexRequestPending: false,
    isReIndexRequestFailed: false,

    isReTagPending: false,
    isReTagFailed: false,

    isPruneTagsPending: false,
    isPruneTagsFailed: false,

    isGetImportStatePending: false,
    isGetImportStateFailed: false,

    isImportBackupPending: false,
    isImportBackupFailed: false,

    importCollection: [],
    isGetImportCollectionPending: false,
    isGetImportCollectionFailed: false,

    exportUrl: null,

    settings: {
      fastLaneCheckUpdates: '',
      fastLaneEmails: []
    },
    isGetSettingsPending: false,
    isGetSettingsFailed: false,
    isUpdateSettingsPending: false,
    isUpdateSettingsFailed: false
  },
  mutations: {
    [REINDEX.STARTED](state) {
      state.isReIndexRequestFailed = false;
      state.isReIndexRequestPending = true;
    },
    [REINDEX.COMPLETED](state) {
      state.isReIndexRequestPending = false;
    },
    [REINDEX.FAILED](state) {
      state.isReIndexRequestFailed = true;
      state.isReIndexRequestPending = false;
    },

    [RE_TAG.STARTED](state) {
      state.isReTagFailed = false;
      state.isReTagPending = true;
    },
    [RE_TAG.COMPLETED](state) {
      state.isReTagPending = false;
    },
    [RE_TAG.FAILED](state) {
      state.isReTagFailed = true;
      state.isReTagPending = false;
    },

    [PRUNE_TAGS.STARTED](state) {
      state.isPruneTagsFailed = false;
      state.isPruneTagsPending = true;
    },
    [PRUNE_TAGS.COMPLETED](state) {
      state.isPruneTagsPending = false;
    },
    [PRUNE_TAGS.FAILED](state) {
      state.isPruneTagsFailed = true;
      state.isPruneTagsPending = false;
    },

    [IMPORT_BACKUP.STARTED](state) {
      state.isImportBackupFailed = false;
      state.isImportBackupPending = true;
    },
    [IMPORT_BACKUP.COMPLETED](state) {
      state.isImportBackupPending = false;
    },
    [IMPORT_BACKUP.FAILED](state) {
      state.isImportBackupFailed = true;
      state.isImportBackupPending = false;
    },

    [GET_IMPORT_COLLECTION.STARTED](state) {
      state.isGetImportCollectionFailed = false;
      state.isGetImportCollectionPending = true;
    },
    [GET_IMPORT_COLLECTION.COMPLETED](state, { collection }) {
      state.isGetImportCollectionPending = false;
      state.importCollection = collection;
    },
    [GET_IMPORT_COLLECTION.FAILED](state) {
      state.isGetImportCollectionFailed = true;
      state.isGetImportCollectionPending = false;
    },
    [GET_EXPORT_URL.COMPLETED](state, url) {
      state.exportUrl = url;
    },

    [GET_SETTINGS.STARTED](state) {
      state.isGetSettingsFailed = false;
      state.isGetSettingsPending = true;
    },
    [GET_SETTINGS.COMPLETED](state, settings) {
      state.isGetSettingsPending = false;
      state.settings = settings;
    },
    [GET_SETTINGS.FAILED](state) {
      state.isGetSettingsFailed = true;
      state.isGetSettingsPending = false;
    },
    [UPDATE_SETTINGS.STARTED](state) {
      state.isUpdateSettingsFailed = false;
      state.isUpdateSettingsPending = true;
    },
    [UPDATE_SETTINGS.COMPLETED](state) {
      state.isUpdateSettingsPending = false;
    },
    [UPDATE_SETTINGS.FAILED](state) {
      state.isUpdateSettingsFailed = true;
      state.isUpdateSettingsPending = false;
    }
  },
  actions: {
    async getExportUrl({ commit }) {
      const url = await httpClient.get(`api/template-storage/export-url`);
      commit(GET_EXPORT_URL.COMPLETED, url);
    },
    async getImportCollection({ commit }) {
      try {
        commit(GET_IMPORT_COLLECTION.STARTED);

        const collection = await httpClient.get(`api/template-storage/import`);

        commit(GET_IMPORT_COLLECTION.COMPLETED, { collection });
      } catch (e) {
        commit(GET_IMPORT_COLLECTION.FAILED);
        throw e;
      }
    },
    async importBackup({ commit }, data) {
      try {
        commit(IMPORT_BACKUP.STARTED);

        const response = httpClient.request(`/api/template-storage/import`, {
          json: false,
          method: 'POST',
          body: jsonObjectToFormData(data)
        });
        commit(IMPORT_BACKUP.COMPLETED);

        return response;
      } catch (e) {
        commit(IMPORT_BACKUP.FAILED);
        throw e;
      }
    },
    async reindex({ commit }) {
      try {
        commit(REINDEX.STARTED);
        const response = await httpClient.post(`/api/applications/reindex`);

        commit(REINDEX.COMPLETED);

        return response;
      } catch (e) {
        commit(REINDEX.FAILED);
        throw e;
      }
    },
    async reTag({ commit }) {
      try {
        commit(RE_TAG.STARTED);
        const response = await httpClient.post(`api/categorization/queries/apply`);

        commit(RE_TAG.COMPLETED);

        return response;
      } catch (e) {
        commit(RE_TAG.FAILED);
        throw e;
      }
    },
    async pruneTags({ commit }) {
      try {
        commit(PRUNE_TAGS.STARTED);
        const response = await httpClient.post(`api/categorization/queries/prune`);

        commit(PRUNE_TAGS.COMPLETED);

        return response;
      } catch (e) {
        commit(PRUNE_TAGS.FAILED);
        throw e;
      }
    },

    async getSettings({ commit }) {
      try {
        commit(GET_SETTINGS.STARTED);
        const settings = await httpClient.get(`/api/ms-graph-email-connector/settings`);
        commit(GET_SETTINGS.COMPLETED, settings);
      } catch (e) {
        commit(GET_SETTINGS.FAILED);
        throw e;
      }
    },
    async updateSettings({ commit }, newSettings) {
      try {
        commit(UPDATE_SETTINGS.STARTED);
        await httpClient.post(`/api/ms-graph-email-connector/settings`, newSettings);
        commit(UPDATE_SETTINGS.COMPLETED);
      } catch (e) {
        commit(UPDATE_SETTINGS.FAILED);
        throw e;
      }
    }
  }
};
